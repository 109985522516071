import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import gsap from 'gsap'
import { StaticImage } from "gatsby-plugin-image"
// import "../styles/pages/links.scss"
import "../styles/pages/links.scss"



const LinksPage = ({ transitionStatus }) => {

  const links = [
    {
      title: "Label",
      email: "jessica@joyfulnoiserecordings.com"
    },
    {
      title: "Booking",
      email: "robin@inlandempiretouring.com"
    },
    {
      title: "US Publicity",
      email: "andi@terrorbird.com"
    },
    {
      title: "UK Publicity",
      email: "frankie@stereosanctity.co.uk"
    },
  ]

  React.useEffect(() => {
    if (transitionStatus === 'entering') {
      gsap.to('.links', {
        autoAlpha: 1,
        duration: 1, //if we are entering the page, let's make the div with class .link-page visible in one second
      });
    }
    if (transitionStatus === 'exiting') {
      gsap.to('.links', { autoAlpha: 0, duration: 1 });//if we are exiting  the page, let's make the div with class .link-page transparent in one second
    }
  }, [transitionStatus]);
  return (
    <Layout currentPage={`links`}>
      <Seo title="Links" />


      <Container className={'links'} maxWidth={false} >
        <div className="left">
          <StaticImage
            alt="Karen - Photo by Michael McCleary"
            objectFit="cover"
            src={"../images/photo-page/e.jpg"}
          />

        </div>
        <div className="right">
          <Typography component="h1" variant="h1" color="primary.dark">
            Links
          </Typography>
          {
            links.map((link, index) => {
              return (
                <div className="item" key={index}>
                  <Typography variant="body2Bold" color="primary.dark">
                    {link.title}
                  </Typography>
                  {" - "}
                  <a href={`mailto:${link.email}`} target="_blank" rel="noreferrer">
                    <Typography variant="body2" color="primary.dark">
                      {link.email}
                    </Typography>
                  </a>
                </div>
              )
            })
          }



        </div>
        {/* You can use a GatsbyImage component if the image is dynamic */}
        <StaticImage
          style={{
            gridArea: "1/1",
            // You can set a maximum height for the image, if you wish.
            // maxHeight: 600,
          }}
          layout="fullWidth"

          // This is a presentational image, so the alt should be an empty string
          alt=""
          src={
            "../images/album.jpeg"
          }
        />

      </Container>






    </Layout>
  )
}

export default LinksPage
